<template>
    <b-modal id="terms-and-conditions-modal" scrollable title="Terms and Conditions" size="xl" hide-footer>
        <component :is="`${capitalizeFirstLetter(locale)}TermsAndConditionsContent`"/>
    </b-modal>
</template>

<script>

import NzTermsAndConditionsContent from '~/components/static/NzTermsAndConditionsContent'
import AusTermsAndConditionsContent from '~/components/static/AusTermsAndConditionsContent'

export default {
    name: 'TermsAndConditionsModal',
    components: {
        NzTermsAndConditionsContent,
        AusTermsAndConditionsContent
    }
}
</script>
