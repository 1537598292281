<template>
    <b-modal id="add-to-collection-modal" size="lg" scrollable title="Add to Collections" hide-footer centered>
        <div v-if="stockItem" class="container">
            <div class="row">
                <div class="col-lg-5 d-none d-lg-block">
                    <div class="similar-images-wrap">
                        <div class="stock-image-search-result" :style="`--width:${stockItem.thumbnails['medium'].width/2};--height:${stockItem.thumbnails['medium'].height/2};`">
                            <div style="overflow:hidden;border-radius:5px;" :style="`height:100%;width:100%;position:relative;`">
                                <img :src="stockItem.thumbnails['medium'].url" :alt="stockItem.description">
                            </div>
                        </div>
                    </div>
                    <span>{{ stockItem.code }}</span><br>
                    <span>by {{ stockItem.contributor.name }}</span>
                </div>
                <div class="col-12 col-lg-7">
                    <nuxt-link :to="{ name: 'collections' }" class="btn-color d-inline-block float-lg-right mb-4" @click.native="$bvModal.hide('add-to-collection-modal')">Manage &amp; Share Collections ></nuxt-link>
                    <div class="clearfix"/>
                    <collections-list ref="collectionsList" :stock-item="stockItem"/>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CollectionsList from '~/components/collection/CollectionsList.vue'

export default {
    name: 'AddToCollectionModal',
    components: { CollectionsList },
    data () {
        return {
            stockItem: null
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            localeVariables: 'data/localeVariables'
        })
    },
    created () {
        this.$nuxt.$on('addToCollections', this.setAndShow)
    },
    beforeDestroy () {
        this.$nuxt.$off('addToCollections', this.setAndShow)
    },
    methods: {

        setAndShow (stockItem) {
            this.stockItem = stockItem

            this.$bvModal.show('add-to-collection-modal')
        }
    }
}
</script>
