<template>
    <div id="error-container">
        <div>
            <div v-if="error.statusCode === 404">
                <h1>We couldn't find anything</h1>
                <p>The url you tried to access doesnt exist or was removed.</p>
            </div>
            <div v-else>
                <h1>An unexpected error has occurred</h1>
                <p>Something has gone wrong. Our engineers have been notified.</p>
                <p>Please try again later.</p>
            </div>
            <nuxt-link class="btn btn-primary" to="/">Go to home page</nuxt-link>
        </div>
    </div>
</template>
<script>
export default {
    props: ['error']
}
</script>
