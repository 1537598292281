<template>
    <b-modal id="content-licence-agreement-modal" scrollable title="Content Licence Agreement" size="xl" hide-footer>
        <component :is="`${capitalizeFirstLetter(locale)}ContentLicenceAgreementContent`"/>
    </b-modal>
</template>

<script>
import NzContentLicenceAgreementContent from '~/components/static/NzContentLicenceAgreementContent'
import AusContentLicenceAgreementContent from '~/components/static/AusContentLicenceAgreementContent'

export default {
    name: 'ContentLicenceAgreementModal',
    components: {
        NzContentLicenceAgreementContent,
        AusContentLicenceAgreementContent
    }
}
</script>
