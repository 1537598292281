<template>
    <b-modal id="announcement-modal" scrollable title="Get Notified" hide-footer>
        <form class="auth-form" @submit.prevent="sendContact" @keydown="form.onKeydown($event)">
            <div class="form-row">
                <div class="form-group col-sm-12 mb-3">
                    <div class="hint-text">We're launching corporate accounts soon.<br>Register below and we'll let you know when this feature is live.</div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" type="text" name="email" class="form-control" placeholder="Email Address">
                    <has-error :form="form" field="email"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <b-form-checkbox id="updates" v-model="form.updates" :class="{ 'is-invalid': form.errors.has('updates') }" name="updates">
                        I am happy to receive emails from Truestock about other feature updates
                    </b-form-checkbox>
                    <has-error :form="form" field="content_licence_agreement"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <v-button variant="primary" class="float-right btn-lg" :loading="form.busy">
                        Submit
                    </v-button>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
    name: 'AnnouncementModal',
    data () {
        return {
            form: new Form({
                email: '',
                updates: true
            })
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    watch: {
        user (newUser, oldUser) {
            this.setUserFields()
        }
    },
    mounted () {
        this.setUserFields()
    },
    methods: {
        setUserFields () {
            if (this.user) {
                this.form.email = this.user.email
            }
        },
        async sendContact () {
            const _this = this
            await this.form.post('/contact/announcement').then((data) => {
                _this.form.message = ''
                this.$toast.success("Thanks! We'll be in touch.")
                this.$bvModal.hide('announcement-modal')
            }).catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>
