<template>
    <b-modal id="privacy-policy-modal" scrollable title="Privacy Policy" size="xl" hide-footer>
        <component :is="`${capitalizeFirstLetter(locale)}PrivacyPolicyContent`"/>
    </b-modal>
</template>

<script>
import NzPrivacyPolicyContent from '~/components/static/NzPrivacyPolicyContent'
import AusPrivacyPolicyContent from '~/components/static/AusPrivacyPolicyContent'

export default {
    name: 'PrivacyPolicyModal',
    components: {
        NzPrivacyPolicyContent,
        AusPrivacyPolicyContent
    }
}
</script>
