import Vue from 'vue'

if (!Vue.__money_optional_decimal_mixin__) {
    Vue.__money_optional_decimal_mixin__ = true
    Vue.mixin({
        methods: {
            moneyOptionalDecimal (number) {
                number = parseFloat(number)

                if (Math.round(number) !== number) {
                    return new Intl.NumberFormat('en-NZ', {
                        style: 'currency',
                        currency: 'NZD',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(parseFloat(number))
                } else {
                    return new Intl.NumberFormat('en-NZ', {
                        style: 'currency',
                        currency: 'NZD',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                    }).format(parseFloat(number))
                }
            },
            moneyForceDecimal (number) {
                return new Intl.NumberFormat('en-NZ', {
                    style: 'currency',
                    currency: 'NZD',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }).format(parseFloat(number))
            }
        }
    })
}
