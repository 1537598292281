import Vue from 'vue'

if (!Vue.__seconds_to_timestamp_mixin__) {
    Vue.__seconds_to_timestamp_mixin__ = true
    Vue.mixin({
        methods: {
            secondsToTimestamp (duration) {
                const minutes = Math.floor(duration / 60)
                const seconds = (duration % 60 ? duration % 60 : 0)

                return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
            }
        }
    })
}
