<template>
    <div class="collection-row row" :class="{active:collection.containsStockItem}" @click="toggleInCollection">
        <div class="col-3 pl-0">
            <div class="collection-preview" :style="`background: url(${collection.preview ? collection.preview.thumbnails['small'].url : collection.defaultPreview}) no-repeat center;background-size:cover;`">
            </div>
        </div>
        <div class="col-9 pr-0" :title="collection.name">
            <div class="name">{{ collection.name }} <span v-if="collection.shared">(shared)</span></div>
            <span class="count">{{ collection.images }} {{ collection.images === 1 ? 'image' : 'images' }}</span>
            <span v-if="collection.videos > 0" class="count ml-2">{{ collection.videos }} {{ collection.videos === 1 ? 'video' : 'videos' }}</span>
            <a class="btn-color">
                <b-icon-circle-fill v-if="toggling" animation="throb"/>
                <template v-else>
                    <b-icon-check-circle-fill v-if="collection.containsStockItem"/>
                    <b-icon-plus-lg v-else/>
                </template>
            </a>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'CollectionsListRow',
    props: {
        collection: {
            type: Object,
            default: null
        },
        stockItem: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            toggling: false
        }
    },
    methods: {
        toggleInCollection () {
            if (!this.toggling) {
                this.toggling = true

                axios.post('/collections/toggle', {
                    id: this.collection.id,
                    stockCode: this.stockItem.code
                })
                    .then(rsp => rsp.data)
                    .then((response) => {
                        this.$emit('toggled', response.data)
                    })
                    .finally(() => {
                        this.toggling = false
                    })
            }
        }
    }
}
</script>
