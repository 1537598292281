<template>
    <form class="auth-form" @submit.prevent="login" @keydown="form.onKeydown($event)">
        <div class="form-row">
            <div class="form-group col-12">
                <label>Email</label>
                <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" type="email" name="email" class="form-control" placeholder="Email Address">
                <has-error :form="form" field="email"/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12">
                <label>Password</label>
                <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" type="password" name="password" class="form-control" placeholder="Password">
                <has-error :form="form" field="password"/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12">
                <div class="d-flex">
                    <checkbox v-model="form.remember" name="remember">Remember me</checkbox>
                    <nuxt-link :to="{ name: 'password.request' }" class="ml-auto my-auto" @click.native="forgot">
                        Forgot your password?
                    </nuxt-link>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-12 mt-2" :class="{'col-md-6':!fullWidth}">
                <v-button :loading="loading">
                    Log In
                </v-button>
            </div>
        </div>
    </form>
</template>
<script>
import Form from 'vform'

export default {
    name: 'LoginForm',
    middleware: 'guest',
    props: {
        fullWidth: Boolean
    },
    data () {
        return {
            loading: false,
            form: new Form({
                email: '',
                password: '',
                remember: false
            })
        }
    },
    methods: {
        forgot () {
            this.$emit('password-reset')
        },
        async login () {
            this.loading = true
            let data

            // Submit the form.
            try {
                const response = await this.form.post('/login')
                data = response.data
            } catch (e) {
                this.loading = false
                return
            }

            // Save the token.
            this.$store.dispatch('auth/saveToken', {
                token: data.token,
                remember: this.form.remember
            })

            // Fetch the user.
            await this.$store.dispatch('auth/fetchUser')

            this.$emit('login-success')
            this.loading = false
        }
    }
}
</script>
