<template>
    <form class="auth-form" @submit.prevent="register" @keydown="form.onKeydown($event)">
        <div class="form-row">
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label>Account Type</label>
                <b-form-select v-model="form.account_type" :options="accountTypes" :class="{ 'is-invalid': form.errors.has('account_type') }" name="account_type" class="form-control"/>
                <has-error :form="form" field="account_type"/>
            </div>
            <div v-if="form.account_type === 'business'" class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">Business Name</label>
                <input v-model="form.business_name" :class="{ 'is-invalid': form.errors.has('business_name') }" type="text" name="business_name" class="form-control">
                <has-error :form="form" field="business_name"/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">First Name</label>
                <input v-model="form.first_name" :class="{ 'is-invalid': form.errors.has('first_name') }" type="text" name="first_name" class="form-control">
                <has-error :form="form" field="first_name"/>
            </div>
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">Last Name</label>
                <input v-model="form.last_name" :class="{ 'is-invalid': form.errors.has('last_name') }" type="text" name="last_name" class="form-control">
                <has-error :form="form" field="last_name"/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">Email</label>
                <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" type="email" name="email" class="form-control">
                <has-error :form="form" field="email"/>
            </div>
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">Industry</label>
                <b-form-select v-model="form.industry_id" :options="industries" :class="{ 'is-invalid': form.errors.has('industry_id') }" name="industry_id" class="form-control"/>
                <has-error :form="form" field="industry_id"/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">Password</label>
                <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" type="password" name="password" class="form-control">
                <has-error :form="form" field="password"/>
            </div>
            <div class="form-group col-md-12" :class="{'col-lg-6':!fullWidth}">
                <label class="required">Confirm Password</label>
                <input v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" type="password" name="password_confirmation" class="form-control">
                <has-error :form="form" field="password_confirmation"/>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12 mt-4" :class="{'col-lg-6':!fullWidth && !fullWidthSubmit}">
                <v-button :loading="loading">
                    Register Now
                </v-button>
            </div>
        </div>
    </form>
</template>
<script>
import Form from 'vform'

export default {
    name: 'RegisterForm',
    middleware: 'guest',
    props: {
        fullWidth: Boolean,
        fullWidthSubmit: Boolean,
        industries: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data: () => ({
        loading: false,
        accountTypes: [
            {
                value: 'personal',
                text: 'Personal'
            },
            {
                value: 'business',
                text: 'Business'
            }
        ],
        form: new Form({
            account_type: 'personal',
            first_name: '',
            last_name: '',
            email: '',
            industry_id: '',
            password: '',
            password_confirmation: ''
        })
    }),
    methods: {
        async register () {
            this.loading = true
            let data

            // Register the user.
            try {
                const response = await this.form.post('/register')
                data = response.data
            } catch (e) {
                this.loading = false
                return
            }

            // Log in the user.
            const { data: { token } } = await this.form.post('/login')

            // Save the token.
            await this.$store.dispatch('auth/saveToken', { token })

            // Fetch the user.
            await this.$store.dispatch('auth/fetchUser')

            this.$toast.success(`Hi ${data.first_name}. Your account was created.`)

            this.$emit('registration-success')
            this.loading = false
        }
    }
}
</script>
