import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
    Vue.use(VueGtag, {
        pageTrackerTemplate (to) {
            return {
                page_title: to.page_title,
                page_path: to.page_path
            }
        },
        config: {
            id: app.$config.gtagId,
            params: {
                debug_mode: !(process.env.APP_ENV === 'production')
            }
        },
        deferScriptLoad: true
    }, app.router)
}
