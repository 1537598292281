<template>
    <video ref="videoPlayer" class="video-js" preload="none" :poster="posterUrl">
        <!--        <source :src="fallbackVideoUrl" type="video/mp4">-->
    </video>
</template>

<script>
import videojs from 'video.js'

export default {
    name: 'VideoPlayer',
    props: {
        posterUrl: {
            type: String,
            default () {
                return ''
            }
        },
        fallbackVideoUrl: {
            type: String,
            default () {
                return ''
            }
        }
    },
    data () {
        return {
            player: null,
            options: null
        }
    },
    beforeDestroy () {
        if (this.player) {
            const _this = this
            this.$refs.videoPlayer.removeEventListener('timeupdate', function () {
                _this.timeUpdate(this)
            })

            this.player.dispose()
        }
    },
    methods: {

        startPlayer (options) {
            this.options = options

            this.player = videojs(this.$refs.videoPlayer, this.options)

            this.player.on('ready', () => {
                this.$emit('load')
            })

            const _this = this
            this.$refs.videoPlayer.addEventListener('timeupdate', function () {
                _this.timeUpdate(this)
            })
        },
        play () {
            this.$refs.videoPlayer.play()
        },
        pause () {
            this.$refs.videoPlayer.pause()
        },
        reset () {
            this.$refs.videoPlayer.currentTime = 0
        },
        timeUpdate (obj) {
            this.$emit('timeupdate', Math.round(obj.currentTime))
        }
    }
}
</script>

<style scoped>
.video-js {
    width: 100% !important;
    height: auto !important;
}

video {
    height: 100% !important;
}
</style>
