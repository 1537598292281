<template>
    <input :id="`input-location-${id}`" ref="inputLocation" v-model="location" class="location-search-input" type="text" name="location-search" @input="location_dirty = true" @blur="fixLocation"/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'LocationSearchInput',
    props: {
        autoStart: {
            type: Boolean,
            default: true
        },
        useFormattedAddress: {
            type: Boolean,
            default: false
        },
        types: {
            type: Array,
            default () {
                return ['(regions)']
            }
        }
    },
    data () {
        return {
            id: '',
            autocomplete: null,
            location_dirty: false,
            location_old: '',
            location: '',
            location_lat: '',
            location_lng: ''
        }
    },
    computed: {
        ...mapGetters({
            localeVariables: 'data/localeVariables'
        })
    },
    created () {
        this.id = this._uid
    },
    mounted () {
        if (this.autoStart) {
            this.start()
        }
    },
    methods: {
        start () {
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(`input-location-${this._uid}`), {
                fields: ['address_components', 'formatted_address', 'geometry.location'],
                types: this.types,
                componentRestrictions: { country: this.localeVariables['isoAlpha2'] }
            })
            this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace()

                this.location_dirty = false
                this.location_lat = place.geometry.location.lat()
                this.location_lng = place.geometry.location.lng()

                if (this.useFormattedAddress) {
                    this.changed(place)
                    this.location = this.location_old = place.formatted_address
                } else {
                    this.location = this.location_old = this.changed(place)
                }
            })
        },
        fixLocation () {
            const trimmed = this.location.trim()

            if (trimmed !== '') {
                this.location = this.location_old
            } else {
                this.location = trimmed

                this.$emit('changed', null)
            }
        },
        changed (place) {
            const addressComponents = this.getAddressComponents(place)
            this.$emit('changed', addressComponents)

            return addressComponents.location
        },
        getAddressComponents (place) {
            const data = {
                location: null,
                location_lat: this.location_lat,
                location_lng: this.location_lng,
                placeRaw: place,
                address: null,
                suburb: null,
                city: null,
                region: null,
                postalCode: null,
                country: null,
                g_formatted_address: place.formatted_address
            }

            let streetNumber = ''
            let streetName = null

            const originalAddressParts = this.$refs.inputLocation.value.split(' ')

            if (/\d/.test(originalAddressParts[0])) {
                streetNumber = originalAddressParts[0]
            }

            place.address_components.forEach((component) => {
                if (component.types.includes('route')) {
                    streetName = component.long_name
                }
                if (component.types.includes('sublocality')) {
                    data.suburb = component.long_name
                }
                if (component.types.includes('locality')) {
                    data.city = component.long_name
                }
                if (component.types.includes('administrative_area_level_1')) {
                    data.region = component.long_name
                }
                if (component.types.includes('postal_code')) {
                    data.postalCode = component.long_name
                }
                if (component.types.includes('country')) {
                    data.country = component.long_name
                }
            })

            if (streetNumber && streetName) {
                data.address = `${streetNumber} ${streetName}`
            }

            const location = []
            const streetParts = []
            const cityParts = []

            if (streetNumber) {
                streetParts.push(streetNumber)
            }
            if (streetName) {
                streetParts.push(streetName)
            }

            if (streetParts.length > 0) {
                location.push(streetParts.join(' '))
            }

            if (data.suburb) {
                location.push(data.suburb)
            }

            if (data.city) {
                cityParts.push(data.city)
            }
            if (data.postalCode) {
                cityParts.push(data.postalCode)
            }
            if (cityParts.length > 0) {
                location.push(cityParts.join(' '))
            }

            if (data.region !== data.city) {
                location.push(data.region)
            }

            location.push(data.country)

            data.location = location.join(', ')

            return data
        },
        reset () {
            this.location_dirty = false
            this.location = ''
            this.location_lat = ''
            this.location_lng = ''
        },
        forceSet (location) {
            this.location = this.location_old = location
        }
    }
}
</script>
