import shareMutations from 'vuex-shared-mutations'

export default ({ store }) => {
    window.onNuxtReady(nuxt => {
        shareMutations({
            predicate: [
                'cart/ADD_TO_CART', 'cart/UPDATE_IN_CART', 'cart/REMOVE_FROM_CART', 'cart/CLEAR_CART',
                'auth/SET_TOKEN', 'auth/FETCH_USER_SUCCESS', 'auth/FETCH_USER_FAILURE', 'auth/UPDATE_USER', 'auth/LOGOUT']
        })(store)
    })
}
