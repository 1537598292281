<template>
    <div>
        <loading :loading="loading" :full-page="false"/>
        <template v-if="!loading">
            <form id="collection-form" @submit.prevent="addCollection">
                <div class="form-row">
                    <div class="form-group col-12">
                        <input v-model="form.newCollection" :class="{ 'is-invalid': form.errors.has('newCollection') }" type="text" name="new_collection_name" placeholder="Create New Collection" class="form-control">
                        <a class="btn-color" @click="addCollection">
                            <b-icon-circle-fill v-if="creating" animation="throb"/>
                            <b-icon-plus-lg v-else/>
                        </a>
                        <has-error :form="form" field="newCollection"/>
                    </div>
                </div>
            </form>
            <div class="sort-links">Sort <a class="ml-3" :class="{active:currentSort === 'name'}" @click="sortAlphabetically">A-Z</a> <a class="ml-3" :class="{active:currentSort === 'date'}" @click="sortByCreatedDate">Date Created</a></div>
            <hr>
            <div v-if="collections.length === 0" class="alert alert-success">Create your first collection above to get started.</div>
            <div v-else class="collections-list container">
                <collections-list-row v-for="collection in collections" :key="collection.id" :collection="collection" :stock-item="stockItem" @toggled="toggled"/>
            </div>
        </template>
    </div>
</template>
<script>
import axios from 'axios'
import Form from 'vform'
import Loading from '~/components/global/Loading.vue'
import CollectionsListRow from '~/components/collection/CollectionsListRow.vue'

export default {
    name: 'CollectionsList',
    components: {
        CollectionsListRow,
        Loading
    },
    props: {
        stockItem: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            loading: false,
            currentSort: 'date',
            collections: [],
            form: new Form({
                newCollection: ''
            }),
            creating: false
        }
    },
    mounted () {
        this.fetch()
    },
    methods: {
        reSort () {
            if (this.currentSort === 'name') {
                this.sortAlphabetically()
            } else {
                this.sortByCreatedDate()
            }
        },
        sortAlphabetically () {
            this.currentSort = 'name'
            this.collections.sort((a, b) => a.name.localeCompare(b.name))
        },
        sortByCreatedDate () {
            this.currentSort = 'date'
            this.collections.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        },
        async fetch () {
            this.loading = true

            await axios.get(`/collections/${this.stockItem.code}`)
                .then(rsp => rsp.data)
                .then((response) => {
                    this.collections = response.data

                    this.sortByCreatedDate()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        toggled (newCollection) {
            const collection = this.collections.find(c => c.id === newCollection.id)

            collection.containsStockItem = newCollection.containsStockItem
            collection.images = newCollection.images
            collection.videos = newCollection.videos
            collection.preview = newCollection.preview
        },
        addCollection () {
            if (!this.creating) {
                this.creating = true

                this.form.put('/collections').then((data) => {
                    this.$toast.success(`${this.form.newCollection} created`)

                    this.collections.push(data.data.data)

                    this.reSort()

                    this.form.newCollection = ''
                }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.creating = false
                })
            }
        }
    }
}
</script>
