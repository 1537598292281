<template>
    <div class="legal-content">
        <div class="block">
            Truestock is committed to respecting the privacy and security of information received from User(s) of our website and services.
        </div>
        <div class="block">
            We have prepared this Privacy and Data Policy to inform Users how we comply with legal requirements relating to privacy, how we collect, use, disclose or transfer Personal Information supplied by Users or collected by us and the
            ways in which Users can protect their privacy. Our Privacy and Data Policy also specifies other requirements, such as how Users may access, correct and delete information held about them.
        </div>
        <div class="block">
            By using our services, or accessing our website, Users agree to comply with the terms and conditions of this Privacy and Data Policy and agree that Truestock Australia may process (i.e. collect, use, store, transfer, disclose or otherwise
            process) User's Personal Information in accordance with this Privacy and Data Policy (as well as for any other use authorised by the User).
        </div>
        <div class="block">
            Our Privacy and Data Policy explains:
            <ol class="number">
                <li>Consent;</li>
                <li>What information we collect and how;</li>
                <li>How we use Personal Information;</li>
                <li> Who we share Personal Information with and why;</li>
                <li>The steps taken to protect Personal Information under our control;</li>
                <li>Users' data protection rights;</li>
                <li>Communications;</li>
                <li>Links and connections to third party services;</li>
                <li>International data transfers;</li>
                <li>How Truestock Australia retains and deletes Personal Information;</li>
                <li>How to access and update Personal Information; and</li>
                <li>How to contact us.</li>
            </ol>
        </div>
        <h2>1. USER CONSENT</h2>
        <div class="block">
            Truestock Australia provides an online image licensing service, and related services, including products and services supplied by third party providers (together, “our services”). We collect Personal Information in order to be able to
            provide and improve our services, and for the other uses described below.
        </div>
        <div class="block">
            By using our services or providing Personal Information to us, Users consent to our collection, storage, use and disclosure of Personal Information (including any sensitive information provided) in accordance with this Privacy
            and Data Policy.
        </div>
        <h2>2. INFORMATION WE COLLECT</h2>
        <div class="block">
            There are three ways we collect information:
            <ol class="alpha">
                <li>Information Users give us.</li>
                <li>Information we collect when Users use our services.</li>
                <li>Information we collect from third parties.</li>
            </ol>
        </div>

        <h3>(a) Information Users Give Us</h3>
        <div class="block">
            In order to purchase or use our services, a User must provide us with certain contact, billing and Personal Information including (but not limited to) name, address, phone number, email address and other User information. Users
            may also at times provide financial information.
        </div>
        <div class="block">
            Users may also provide us with information when they:
            <ul>
                <li>Register with us;</li>
                <li>Create User accounts and logins;</li>
                <li>Participate in surveys or promotions we organise or promote;</li>
                <li>Communicate with us about image licensing and related matters;</li>
                <li>Subscribe to receive the latest news on our services, and the goods and services of our partners and third party service providers; or</li>
                <li>Contact our support team.</li>
            </ul>
            Users can always choose not to provide us with Personal Information, however this may mean that we are unable to supply our services effectively, or at all.
        </div>
        <h3>(b) Information We Collect from Use of Our Services</h3>
        <div class="block">
            We may automatically collect information (which may include Personal Information) when Users interact with our website, use our services or communicate with us or our partners. This information may include:
        </div>
        <div class="block">
            <ul>
                <li>
                    <strong>System information:</strong> We may collect information about User system(s) including, but not limited to, the User operating system, applications, IP address(es) and other User system environment information.
                </li>
                <li>
                    <strong>Usage information:</strong> We collect information about how Users and their system environment interact with our services. Information that may be collected includes:
                    <ol class="roman">
                        <li>Information relating to the features Users use;</li>
                        <li>The performance of the services and any problems experienced by Users;</li>
                        <li>The pages that Users visit on our website;</li>
                        <li>Website content accessed by Users;</li>
                        <li>Length of the Users' stay on a specific page; and</li>
                        <li>Browser information.</li>
                    </ol>
                </li>
                <li>
                    <strong>Location:</strong> When Users use our services (including our website), we may collect and process information about the User's location. We use various technologies to determine location, including IP addresses
                    and web analytics.
                </li>
                <li>
                    <strong>Device information:</strong> When Users use some of our services, we may receive information about the User's device, such as the hardware model, operating system version, unique device identifier and mobile
                    network information (including phone number).
                </li>
                <li>
                    <strong>Cookies and similar technology:</strong> We ask Users for their consent on our website before using cookies. We use cookies, web beacons and similar technologies to provide our services, and to help collect data.
                    <br>
                    For example, when Users visit our website, we collect information about the pages visited, the User's browser and the User's device. A cookie is a small element of data that a website can send to the User's browser,
                    which may then be stored on the hard drive (session ID cookies will terminate once Users simply close the browser, persistent cookies may however be stored on the User's hard drive for an extended period of time). A
                    cookie does not identify a User personally, but it does identify the User's computer. Cookies allow us, among other things, to monitor traffic patterns, store User preferences and settings, analyse how our services are
                    performing and enable Users to login. Users should be aware that most web browsers are set to accept cookies by default, but allow settings to be adjusted to remove or block cookies. Please note however that rejecting or
                    removing cookies could affect the availability and functionality of our website features, or our services.
                </li>
                <li>
                    <strong>Interest-based advertising (IBA):</strong> IBA allows us to deliver targeted advertising to Users of our services. IBA works by showing you advertisements that are based on the type of content you access or read.
                    For example, as you browse our website, one of the cookies placed on your device will be an advertising cookie so we can better understand what sort of pages or content you are interested in. The information collected
                    about Users' devices enable us to group Users with other devices that have shown similar interests. We can then display advertising to categories of Users that is based on common interests.
                </li>
                <li>
                    <strong>Analytics and advertising:</strong> Our website may use the following analytics and advertising services to assist our marketing and promotional activities:
                    <br>
                    <strong>Google Analytics:</strong> For the purpose of customising and continually optimising our website, we use Google Analytics, a web analytics service provided by Google Inc ("Google"). In this service, pseudonymised
                    usage profiles are created and cookies are used to generate information about your use of this website such as browser type / version, operating system, referrer URL (the previously visited page), IP address for your
                    computer or device, date and time. This information is transmitted to a Google server in the US and stored there. The information is used to evaluate the use of our website, to compile reports on website activity and to
                    provide other services related to website activity and internet usage for the purposes of market research and our website design. This information may also be transferred to third parties if required by law or if third
                    parties process this data. Under no circumstances will your IP address be merged with any other data provided by Google. The IP addresses are anonymised. You can prevent the collection of data generated by the cookie and
                    related to your use of the website (including your IP address) and the processing of this data by Google by downloading and installing a browser add-on (<a target="_blank"
                                                                                                                                                                                href="https://tools.google.com/dlpage/gaoptout?hl=en"
                    >https://tools.google.com/dlpage/gaoptout?hl=en</a>).
                    For more
                    information about privacy related to Google Analytics, see the Google Analytics information at <a target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>.
                    <br>
                    To statistically record the use of our website and to evaluate it for the purpose of optimising our website, we also use Google conversion tracking. In doing so, Google Adwords will set a cookie on your computer if you
                    have reached our website via a Google ad. These cookies lose their validity after 30 days and are not used for personal identification. If the User visits certain pages of the Adwords customer's website and the cookie
                    has not yet expired, Google can detect that the User had previously clicked on the ad and was redirected to this page. Every Adwords customer receives a different cookie. Cookies cannot be tracked via the websites of
                    Adwords customers. The information obtained through the conversion cookie is used to generate conversion statistics for Adwords customers who have opted for conversion tracking. Adwords customers are informed about the
                    total number of users who clicked on their ad and were redirected to a conversion tracking tag page. However, they do not receive information that personally identifies Users. If you do not want to participate in the
                    tracking process, you can also refuse the setting of a cookie - for example, via a browser setting that generally disables the automatic setting of cookies. You can also disable cookies for conversion tracking by setting
                    your browser to block cookies from the domain "www.googleadservices.com". Google's privacy policy on conversion tracking can be found here (<a target="_blank" href="https://services.google.com/sitestats/en.html">https://services.google.com/sitestats/en.html</a>).
                </li>
                <li>
                    <strong>Social Media Plug-ins:</strong> We may use social media plug-ins to make our company better known, and personalise usage, and to assist with registration and sign in for User accounts.
                </li>
                <li>
                    <strong>Other Third Party Service Providers:</strong> We may use other third party service providers for the following reasons:
                    <ol class="roman">
                        <li>To assist our communications and other interactions with people at Truestock Australia and Users;</li>
                        <li>Network monitoring,</li>
                        <li>Website hosting;</li>
                        <li>Data processing, storage and management;</li>
                        <li>Data collaboration;</li>
                        <li>Data analysis and report automation;</li>
                        <li>To conduct audits;</li>
                        <li>To create, manage and send emails and surveys to Users for advertising and marketing;</li>
                        <li>User account and password management, storage and security;</li>
                        <li>Domain name, VPN and proxy software, service and support; and</li>
                        <li>Mobile and wireless connectivity.</li>
                    </ol>
                    For example, we use SendGrid, a cloud based email service within our business. SendGrid is provided by Twilio, which processes and stores Australian User data in the United States and other countries where SendGrid
                    operates. For more information on SendGrid's data protection and privacy, see <a target="_blank" href="https://www.twilio.com/legal/privacy#sendgrid-services">https://www.twilio.com/legal/privacy#sendgrid-services</a>.
                </li>
            </ul>
        </div>
        <div class="block">
            <strong>Consent to Disclosure/Collection:</strong> By using our website or otherwise interacting with us, you:
            <ul>
                <li>
                    Acknowledge that, for information transmitted to Google (through use of Google Analytics, AdWords, cookies or similar technology), to the Social Media Plug-Ins described above, and to the Other Third Party Service
                    Providers described above, those third parties may not be required to protect the information in a way that, overall, provides comparable safeguards to those in the Privacy Act 1988; and
                </li>
                <li>Authorise the disclosure of your Personal Information to those third parties, or collection of your Personal Information by those third parties.</li>
            </ul>
        </div>
        <h3>(c) Information We Collect from Third Parties</h3>
        <div class="block">
            We work closely with third parties (for example, our suppliers and service providers) in order to be able to develop and supply our services, and provide them to Users.
        </div>
        <div class="block">
            We may receive the same kinds of information described in (a) and (b) above from third parties.
        </div>
        <h3>Personal Information Received from Users about Others</h3>
        <div class="block">
            When using our website or services, Users may disclose, and we may collect, Personal Information about someone else. For example, data supplied by Users may contain Personal Information relating to the customers or employees of
            Users.
        </div>
        <div class="block">
            Before disclosing Personal Information to us about someone else, Users must ensure that they have obtained sufficient consent to disclose that information to us, and that, without taking any further steps required by applicable
            data protection or privacy laws, we may collect, use, transfer and disclose such information for the purposes described in this Policy.
        </div>
        <div class="block">
            Users shall remain responsible for all Personal Information collected and processed by the User, and for compliance with applicable privacy and data protection laws.
        </div>
        <h2>3. HOW WE USE PERSONAL INFORMATION</h2>
        <div class="block">
            We collect and use Personal Information in order to be able to provide and improve our services.
        </div>
        <div class="block">
            We also use Personal Information to:
            <ul>
                <li>Communicate, interact and build our relationship with Users, including to better understand User needs and interests, and ensure a quality experience for Users;</li>
                <li>Monitor, develop or optimise the performance of our services;</li>
                <li>Protect and enhance the safety and security of our services and Users;</li>
                <li>Provide information and technical support;</li>
                <li>Carry out billing and licence administration;</li>
                <li>Market and make recommendations on our services;</li>
                <li>Allow our partners and third party providers to provide their services and support to Users;</li>
                <li>Conduct, manage, develop and protect our business;</li>
                <li>Enforce our licensing terms and contributor agreements;</li>
                <li>Comply with laws and regulations in applicable jurisdictions;</li>
                <li>Verify Users' identities and prevent fraud or other unauthorised or illegal activity; and</li>
                <li>Enable third parties to provide services to us.</li>
            </ul>
            For these purposes we may receive, use, store, share, send, combine, reformat, transform, encrypt,
            mask, organise, geomap, update and delete Personal Information (and undertake any further
            processing activities expressed or implied in this Policy). The Personal Information that we collect will
            not be further processed in ways that are incompatible with the initial purposes for which the data was
            collected.
        </div>
        <h2>4. WHO WE SHARE INFORMATION WITH</h2>
        <div class="block">
            We share information, including Personal Information, as necessary to provide Users the service requested or authorised. For example, we may share information with:
            <ul>
                <li>Banks and other entities which process payment transactions when a payment is made;</li>
                <li>Affiliates, subsidiaries and related companies;</li>
                <li>
                    Our partners and third party providers to provide products and services to the User, to communicate with Users (for example, information shared with communication service providers), to provide Users with information on
                    the performance of our services or the services supplied by our partners and third party providers, or to provide Users with remote access or to provide notifications;
                </li>
                <li>To our partners and advertisers to provide tailored product information and advertising that we believe may be relevant to the User;</li>
                <li>
                    Our service providers or suppliers acting and working on our behalf. For example, companies we have hired to assist in protecting and securing our systems and services may need access to Personal Information to provide
                    those services. In such cases, we will require these entities to abide by our data privacy and security requirements, and restrict use of any Personal Information received from us;
                </li>
                <li>
                    Other third parties, when we have a good faith belief that doing so is necessary to:
                    <ol class="number">
                        <li>Comply with any applicable law, regulation, legal process or enforceable governmental request;</li>
                        <li>Protect our Users;</li>
                        <li>Operate and maintain the security of our services, including to prevent or stop an attack on our computer systems or networks;</li>
                        <li>Detect, prevent or otherwise address fraud; or</li>
                        <li>Protect our rights and property, including enforcing our licensing terms and contributor agreements.</li>
                    </ol>
                </li>
                <li>A purchaser, as part of a corporate transaction such as an acquisition, merger or sale of assets.</li>
            </ul>
            From time to time we may use third-party data processors to provide elements of services for us. We will have contracts in place with all of our data processors, to prevent them from doing anything with Users' Personal
            Information unless we or the User has instructed them to do so. Unless the User agrees otherwise, our data processors will.
            <ul>
                <li>Not share Users' Personal Information with any organisation apart from us; and</li>
                <li>Hold Users' Personal Information securely and retain it for the period we instruct.</li>
            </ul>
            We require that our service providers, suppliers and business partners (data processors) agree to keep all User information we share with them confidential. While we provide these third parties with no more information than is
            necessary to perform the function for which we engaged them, Users should be aware that any information provided by the User to these third parties independently/directly is subject to the third parties' respective privacy
            policies and practices.
        </div>
        <div class="block">
            We may also share or use non-Personal Information (i.e. information that is related to a Person but does not personally identify that individual, such as aggregated, anonymised or de-identified data) publically or with third
            parties, such as our partners or third party suppliers. For example, we may share or use information publically to show trends about the general use of our services. This data or information will in no way identify Users or any
            other individual.
        </div>
        <h2>5. STEPS TAKEN TO PROTECT PERSONAL INFORMATION</h2>
        <div class="block">
            Protecting the security of User Personal Information is of the utmost importance to Truestock Australia. We maintain a variety of safeguards and procedures in order to protect Personal Information from unauthorised access, use,
            interference, modification or disclosure.
        </div>
        <div class="block">
            For example, we store Personal Information on computer systems that have password-controlled access and use encryption techniques. We also use intrusion detection technologies to monitor and prevent unauthorised access.
        </div>
        <div class="block">
            Users' Personal Information will only be accessed by people at Truestock Australia who need to use the information for the purposes discussed above.
        </div>
        <div class="block">
            We use a SSL VPN (Secure Sockets Layer Virtual Private Network) to ensure that only authorised personnel at Truestock Australia are given remote access to our computer systems.
        </div>
        <div class="block">
            Some of our services do however require use of the internet, and the internet is not itself a secure environment. We therefore cannot give an absolute assurance or guarantee that User information will be secure at all times.
            Transmission of information over the internet or third-party networks is at the User's own risk. We will notify Users at the first reasonable opportunity if we discover or are advised of a material security breach which has
            resulted in unauthorised access, disclosure or loss of User Personal Information.
        </div>
        <div class="block">
            To help maintain the security of information, Users agree to keep their passwords and account details private and confidential.
        </div>
        <h2>6. USERS' DATA PROTECTION RIGHTS</h2>
        <div class="block">
            Under data protection and privacy laws, Users have rights regarding the Personal Information that we hold/collect. The rights available to Users depend on our reason for processing Users' Personal Information. These rights
            include:
            <ul>
                <li>Right of access: Users have the right to ask us for copies of their Personal Information. This right always applies.</li>
                <li>
                    Right to correction: Users have the right to ask us to update or correct information they think is inaccurate. Users also have the right to ask us to complete information that the User thinks is incomplete. Users are
                    responsible for ensuring that Personal Information provided to us is accurate, complete and up-to-date. We will take reasonable steps to ensure that any further Personal Information that we collect (i.e. information
                    obtained from other sources) is accurate, up-to-date, complete and not misleading.
                </li>
                <li>Right to erasure: Users have the right to ask us to erase their Personal Information in certain circumstances.</li>
                <li>
                    Right to restriction of processing: Users have the right to ask us to restrict or cease the processing of their information in certain circumstances. This may (depending upon the circumstances) include the collection of
                    Personal Information from third parties, collection of sensitive information, disclosure of Personal Information to third parties, transfer of Personal Information overseas, or processing of Personal Information in a
                    particular way, or for a particular purpose, including direct marketing.
                </li>
                <li>
                    Right to data portability: This only applies to information Users have given us. Users have the right to ask that we transfer the information Users have given us from one organisation to another, or give it to the User.
                    This right only applies if we are processing information based on Users' consent, or under (or in talks about entering into) a contract and the processing is automated.
                </li>
            </ul>
            All requests should be sent to us at privacyofficer@truestock.com.au, and include the words 'Attention: The Privacy Officer'. User choices in relation to Personal Information may affect our ability to provide our services, or the
            performance of the services. We will respond to Users as soon as reasonably practicable regarding the impact of the User's requests on the services, any other issues arising and to confirm the User's intention to proceed. If we
            are unable to comply with the request, we will give the User reasons for this decision when we respond (for example, the information may not be readily retrievable and it may not be reasonable or practicable for us to process
            the request in the manner sought. In some instances, it may also be necessary for us to arrange access to User Personal Information through a third party e.g. a third party supplier).
        </div>
        <h2>7. COMMUNICATIONS</h2>
        <div class="block">
            We are committed to full compliance with the Spam Act 2003.
        </div>
        <div class="block">
            By subscribing to email communications, or otherwise providing an email address, Users consent to receiving emails which promote and market our website and services, or the products and services of others, from time to time.
        </div>
        <div class="block">
            Users can unsubscribe from our email communications at any time by clicking the "Unsubscribe" link in any promotional or marketing email, or by emailing privacyofficer@truestock.com.au, and include the words 'Attention: The
            Privacy Officer'.
        </div>
        <div class="block">
            Once a User has unsubscribed from the email communications, the User will be removed from the corresponding email/distribution list as soon as is reasonably practicable.
        </div>
        <h2>8. LINKS AND CONNECTIONS TO THIRD PARTY SERVICES</h2>
        <div class="block">
            Our website contains links to (and may be used by Users in conjunction with) third-party products and services, tools, and websites that are not controlled or managed by us. This Privacy and Data Policy does not cover how these
            organisations process Personal Information. These websites may use cookies. It is the responsibility of those third parties to collect appropriate consents from Users in order to permit their own cookies (to the extent this is
            required by law) and to inform Users about the cookies they use. Users should check the privacy policy on all third-party websites to ensure they are comfortable with third party cookies.
        </div>
        <div class="block">
            We have no responsibility for linked websites, and provide them solely for Users' information and convenience. We specifically disclaim responsibility for their content, privacy practices and terms of use, and we make no
            endorsements, representations or warranties about their accuracy, content or thoroughness.
        </div>
        <div class="block">
            Disclosure of Personal Information by Users to third party service providers is at the User's own risk, and we encourage Users to read the privacy policies applicable to these third-party services. We are not responsible for the
            security or privacy of any information collected by these third-parties.
        </div>
        <h2>9. INTERNATIONAL DATA TRANSFERS</h2>
        <div class="block">
            When we disclose, use or store data, it may be transferred to, and processed in, countries other than Australia. In those countries, there may be differences with Australia's privacy laws.
        </div>
        <div class="block">
            For example:
            <ul>
                <li>
                    <strong>Google Drive:</strong> We use Google Drive within our business, a cloud service provided by Google Inc. which processes and stores User data in Google data centres (see
                    <a target="_blank" href="https://www.google.com/about/datacenters/inside/locations/index.html">https://www.google.com/about/datacenters/inside/locations/index.html</a>). For more information on Google Drive's data
                    protection and
                    privacy, see Google's privacy policy at <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
                </li>
                <li><strong>Other Third Party Service Providers:</strong> We also use the other Third Party Service Providers described in part 2(b) of this Policy.</li>
            </ul>
        </div>
        <div class="block">
            This means that Users' Personal Information may be transferred outside of Australia. However, where we disclose Personal Information to a third party in another country, we place or obtain safeguards to ensure Users' Personal
            Information is protected (except as expressly disclosed in this Policy). Where Users' Personal Information is transferred outside Australia, it will (except as expressly disclosed in this Policy) only be transferred to:
            <ul>
                <li>Countries that have been identified as being subject to privacy laws that, overall, provide comparable safeguards to those under privacy laws in Australia); or</li>
                <li>A foreign Person or entity where we have transfer mechanisms in place to protect Users' Personal Information; or</li>
                <li>A foreign Person or entity that we believe on reasonable grounds is subject to the Privacy Act 1988; or </li>
                <li>A recipient that has agreed to data protection and privacy commitments that, overall, provide comparable safeguards to those under privacy laws in Australia.</li>
                For further information, please contact us using the details set out in the contact section below.
            </ul>
        </div>
        <h2>10. RETENTION AND DELETION OF PERSONAL INFORMATION</h2>
        <div class="block">
            The period of time for which we hold Personal Information that we have collected varies according to what the Personal Information is used or required for, and whether we have an ongoing need to retain it (for example, to
            provide Users with a service they have requested or to comply with applicable legal requirements such as financial record-keeping legislation).
        </div>
        <div class="block">
            Unless there is a legal requirement or justification for us to keep the Personal Information, we will retain it for no longer than is necessary:
            <ul>
                <li>To provide the services requested by the User;</li>
                <li>As part of our usual business record-keeping practices;</li>
                <li>To fulfil the purpose(s) for which the Personal Information was originally collected;</li>
                <li>In accordance with our internal retention policies and practices; or</li>
                <li>For any other purpose(s) authorised by the User.</li>
            </ul>
            Once Personal Information is no longer required, the Personal Information will be deleted, securely destroyed or anonymised.
        </div>
        <h2>11. ACCESSING AND UPDATING USER PERSONAL INFORMATION</h2>
        <div class="block">
            Users are responsible for ensuring that Personal Information provided to us is accurate, complete and up-to-date. This includes personal or sensitive information contained in their User content. We will also take reasonable
            steps to ensure that any Personal Information that we collect (i.e. information obtained from other sources) is accurate, up-to-date, complete and not misleading.
        </div>
        <div class="block">
            We endeavour to provide Users with reasonable access to Personal Information we hold about Users, and Users may request that we update, correct or delete any Personal Information that is inaccurate or inappropriate for the
            purposes for which it was collected.
        </div>
        <div class="block">
            Requests for access to, or the correction of, Personal Information should be emailed to privacyofficer@truestock.com.au, and include the words 'Attention: The Privacy Officer'.
        </div>
        <div class="block">
            We will process requests as soon as reasonably practicable, provided we are not otherwise prevented from doing so by law. If we are unable to meet a User's request, we will explain the reasons why when we respond to the User's
            request. For example, the information may not be readily retrievable and it may not be reasonable or practicable for us to process the request in the manner requested.
        </div>
        <h2>12. HOW TO CONTACT US</h2>
        <div class="block">
            Please contact us if you have any questions or complaints about this Privacy and Data Policy, if you wish to access, update, erase and/or correct Personal Information, or if you otherwise have a question or complaint about the
            manner in which we, our service providers or our partners treat Personal Information.
        </div>
        <div class="block">
            Users may write to Truestock Australia's Privacy Officer by email, including any supporting documentation, at privacyofficer@truestock.com.au, and include the words 'Attention: The Privacy Officer'.
        </div>
        <div class="block">
            Alternatively, you can write to us at:<br>
            Truestock Australia<br>
            Attention: Privacy Officer<br>
            5 Ryan Street<br>
            Lilyfield<br>
            New South Wales 2040<br>
            Australia<br>
        </div>
        <div class="block">
            We will endeavour to respond within 30 days.
        </div>

        <h3>Application of this Privacy and Data Policy</h3>
        <div class="block">
            Our Privacy and Data Policy applies to all of the services offered by us. Our Privacy and Data Policy does not cover the information practices of other companies and organisations (such as our partners or third party service
            providers) that supply, contract and advertise using our website.
        </div>
        <h3>Changes to this Privacy and Data Policy</h3>
        <div class="block">
            We keep this Policy under regular review to make sure it is up to date and accurate. We also reserve the right to change this Policy from time to time, as our practices evolve to meet new requirements, standards, technologies
            and customer feedback. We will post any privacy policy changes on our website (<a target="_blank" href="https://truestock.com.au">https://truestock.com.au</a>) and will update the “last updated” date at the top of this Policy.
            Continued use of our services by Users will be deemed
            acceptance of any amended Policy.
        </div>
        <div class="block">
            We recommend that Users regularly review this Policy to learn how we protect Personal Information.
        </div>
        <h3>Definitions</h3>
        In this Policy, unless the context requires otherwise:
        <table class="definitions">
            <tr>
                <td>Person</td>
                <td>
                    means and includes any natural person, company, corporation, firm, partnership, joint venture, society, organisation or other group or association of persons (whether incorporated or not), trust, state or agency of
                    state, statutory or regulatory body, local authority, government or governmental or semi-governmental body or agency (in each case whether or not having separate legal personality);
                </td>
            </tr>
            <tr>
                <td>Personal Information</td>
                <td>
                    means information about an identifiable individual and includes, without limitation, names, addresses, phone numbers, email addresses, IP addresses and photography work history and experience;
                </td>
            </tr>
            <tr>
                <td>User(s)</td>
                <td>
                    means all Persons accessing our website and/or using our services (including any part of the services) including Persons that load and/or manage content on our website, or that receive or subscribe for any paid content
                    or services, and/or any Persons providing Personal Information to us;
                </td>
            </tr>
            <tr>
                <td>User account</td>
                <td>
                    means any User's account with us; we, us, our, Truestock means Truestock Australia Pty Ltd.
                </td>
            </tr>
        </table>
        <div class="block">
            This policy was last updated on 28 August 2023.
        </div>
    </div>
</template>

<script>
export default {
    name: 'AusPrivacyPolicyContent'
}
</script>

<style scoped>

</style>
