<template>
    <b-modal id="modal-auth" centered hide-footer :size="registerNew ? 'lg' : 'md'">
        <template #modal-title>
            {{ registerNew ? 'Register' : 'Login' }}
        </template>
        <template v-if="registerNew">
            <register-form :industries="industries" :full-width="false" :full-width-submit="true" @registration-success="register"/>
            <div class="auth-separator"><span>OR</span></div>
            <v-button class="btn btn-primary" @click.native="registerNew=false">Login to an existing account</v-button>
        </template>
        <template v-else>
            <login-form :full-width="true" @login-success="login" @password-reset="hideModal"/>
            <div class="auth-separator"><span>OR</span></div>
            <v-button class="btn btn-primary" @click.native="registerNew=true">Register a new account</v-button>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from '~/components/auth/LoginForm'
import RegisterForm from '~/components/auth/RegisterForm'

export default {
    components: {
        RegisterForm,
        LoginForm
    },
    data () {
        return {
            registerNew: false,
            callbackEvent: null,
            callbackPayload: {}
        }
    },
    computed: mapGetters({
        industries: 'data/industries',
        user: 'auth/user'
    }),
    methods: {
        show (callbackEvent = null, callbackPayload = {}) {
            this.registerNew = false
            this.$bvModal.show('modal-auth')

            this.callbackEvent = callbackEvent
            this.callbackPayload = callbackPayload
        },
        hideModal () {
            this.$bvModal.hide('modal-auth')

            if (this.callbackEvent) {
                this.$nuxt.$emit(this.callbackEvent, this.callbackPayload)
            }
        },
        login () {
            this.hideModal()

            this.$toast.success(`Welcome back ${this.user.first_name}`)
        },
        register () {
            this.hideModal()

            this.$toast.success('Welcome to Truestock')
        }
    }
}
</script>
