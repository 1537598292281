import Vue from 'vue'

Vue.mixin({
    computed: {
        appUrl () {
            return process.env.appUrl
        },
        assetsUrl () {
            return process.env.assetsUrl
        },
        notificationEmail () {
            return process.env.notificationEmail
        }
    }
})
