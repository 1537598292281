import Vue from 'vue'
import _ from 'lodash'

// state
export const state = () => ({
    items: [],
    total: null
})

// getters
export const getters = {
    items: state => state.items,
    itemCount: state => state.items.length,
    inCart: state => (image) => {
        for (const cartItem of state.items) {
            if (cartItem.image.code === image.code) {
                return true
            }
        }

        return false
    },
    getItem: state => (image) => {
        for (const cartItem of state.items) {
            if (cartItem.image.code === image.code) {
                return cartItem
            }
        }

        return false
    }
}

// mutations
export const mutations = {
    ADD_TO_CART (state, item) {
        state.items.push(_.cloneDeep(item))
    },

    UPDATE_IN_CART (state, item) {
        state.items.forEach((value, index) => {
            if (value.image.code === item.image.code) {
                Vue.set(state.items, index, _.cloneDeep(item))
            }
        })
    },

    REMOVE_FROM_CART (state, item) {
        let foundIndex
        state.items.forEach((value, index) => {
            if (value.image.code === item.image.code) {
                foundIndex = index
            }
        })

        if (foundIndex !== null) {
            state.items.splice(foundIndex, 1)
        }
    },

    CLEAR_CART (state) {
        state.items = []
        state.total = null
    }
}

// actions
export const actions = {
    addToCart ({
        commit,
        dispatch
    }, { item }) {
        commit('ADD_TO_CART', item)
    },
    updateInCart ({
        commit,
        dispatch
    }, { item }) {
        commit('UPDATE_IN_CART', item)
    },
    removeItem ({
        commit,
        dispatch
    }, { item }) {
        commit('REMOVE_FROM_CART', item)
    },
    clearCart ({
        commit,
        dispatch
    }) {
        commit('CLEAR_CART')
    }
}
