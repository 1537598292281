import Vue from 'vue'

if (!Vue.__number_only_input_mixin__) {
    Vue.__number_only_input_mixin__ = true

    Vue.mixin({
        methods: {
            numberOnlyInput (evt) {
                evt = (evt) || window.event
                const charCode = (evt.which) ? evt.which : evt.keyCode
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 &&
                    charCode !== 37 && charCode !== 38 && charCode !== 39 && charCode !== 40 &&
                    !(charCode >= 96 && charCode <= 105)
                ) {
                    evt.preventDefault()
                } else {
                    return true
                }
            }
        }
    })
}
