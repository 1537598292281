<template>
    <footer>
        <component :is="`${capitalizeFirstLetter(locale)}FooterContent`"/>
        <privacy-policy-modal/>
        <terms-and-conditions-modal/>
        <content-licence-agreement-modal/>
        <contact-modal/>
        <announcement-modal/>
        <add-to-collection-modal/>
    </footer>
</template>

<script>
import NzFooterContent from '~/components/footer/NzFooterContent'
import AusFooterContent from '~/components/footer/AusFooterContent'
import ContactModal from '~/components/ContactModal'
import ContentLicenceAgreementModal from '~/components/static/ContentLicenceAgreementModal'
import TermsAndConditionsModal from '~/components/static/TermsAndConditionsModal'
import PrivacyPolicyModal from '~/components/static/PrivacyPolicyModal'
import AnnouncementModal from '~/components/AnnouncementModal'
import AddToCollectionModal from '~/components/collection/AddToCollectionModal'

export default {
    components: {
        AddToCollectionModal,
        NzFooterContent,
        AusFooterContent,
        AnnouncementModal,
        ContactModal,
        ContentLicenceAgreementModal,
        TermsAndConditionsModal,
        PrivacyPolicyModal
    }
}
</script>
