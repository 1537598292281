<template>
    <div class="layout" :class="{'half-back':isSearchPage(),'light-bg':isProfilePage(),'welcome':isWelcomePage()}">
        <navbar/>

        <div class="content">
            <div v-if="maxPromotion() && !isWelcomePage()" id="promotion-banner">
                <b-icon-cart-fill/>
                {{ maxPromotion().name }} sale <br/><strong>• {{ maxPromotion().discount_pcnt }}% off site wide •</strong> ends {{ $moment(maxPromotion().ends_at).format('Do MMM') }}
            </div>
            <div v-else-if="!isNoBannerPage() && !isProfilePage() && !isSearchPage()" :class="{ 'announcement-hero-row': isHeroImagePage() }" class="announcement-banner-row">
                <div class="container">
                    <div class="announcement-banner">
                        <a class="pointer text-decoration-none" @click="showNewsletterModal">
                            <b-icon-envelope class="btn-color"/>
                            <span class="text-color">Register for the newsletter to get a monthly dose of new images uploaded</span>
                        </a>
                    </div>
                </div>
            </div>
            <nuxt/>
        </div>

        <page-footer/>
        <b-modal id="newsletter-modal" scrollable title="Newsletter Registration" hide-footer centered>
            <form class="auth-form" @submit.prevent="newsletterSubscribe" @keydown="newsletter.form.onKeydown($event)">
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <label class="required">Name</label>
                        <input v-model="newsletter.form.name" :class="{ 'is-invalid': newsletter.form.errors.has('name') }" type="text" name="name" class="form-control" placeholder="">
                        <has-error :form="newsletter.form" field="name"/>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <label class="required">Email</label>
                        <input v-model="newsletter.form.email" :class="{ 'is-invalid': newsletter.form.errors.has('email') }" type="text" name="email" class="form-control" placeholder="">
                        <has-error :form="newsletter.form" field="email"/>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <v-button variant="primary" class="float-right btn-lg" :loading="newsletter.form.busy">
                            Submit
                        </v-button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Form from 'vform'
import moment from 'moment'
import { mapGetters } from 'vuex'
import Navbar from '~/components/navbar/Navbar'
import PageFooter from '~/components/footer/PageFooter'

export default {
    components: {
        PageFooter,
        Navbar
    },
    data () {
        return {
            newsletter: {
                form: new Form({
                    name: '',
                    email: ''
                })
            }
        }
    },
    computed: {
        ...mapGetters({
            maxPromotion: 'data/maxPromotion'
        })
    },
    methods: {
        isHeroImagePage () {
            return this.$nuxt.$route.name === 'welcome' ||
                this.$nuxt.$route.name === 'about'
        },
        isNoBannerPage () {
            return this.$nuxt.$route.name === 'apply-to-join' ||
                this.$nuxt.$route.name === 'tailored' ||
                this.$nuxt.$route.name === 'tailored.case-study' ||
                this.$nuxt.$route.name === 'pre-purchase' ||
                this.$nuxt.$route.name === 'contributor-profile' ||
                this.$nuxt.$route.name === 'contributor-album' ||
                this.$nuxt.$route.name === 'contributor-album-full'
        },
        isSearchPage () {
            return this.$nuxt.$route.name === 'search'
        },
        isProfilePage () {
            return this.$nuxt.$route.path.includes('account') || this.$nuxt.$route.path.includes('/purchase')
        },
        isWelcomePage () {
            return this.$nuxt.$route.name === 'welcome'
        },
        showNewsletterModal () {
            this.$bvModal.show('newsletter-modal')
        },
        async newsletterSubscribe () {
            await this.newsletter.form.post('/contact/newsletter').then((data) => {
                this.newsletter.form.name = ''
                this.newsletter.form.email = ''

                this.$toast.success('Thanks for registering! Keep an eye on your inbox for our next newsletter')

                this.$bvModal.hide('newsletter-modal')
            }).catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>
