<template>
    <div class="loading d-none" :class="{ 'd-block': loading, 'full-page' : fullPage }">
        <div class="loading-content-wrap">
            <div class="spinner-border align-self-center text-primary" role="status">
                <span class="sr-only">{{ loadingText }}</span>
            </div>
            <div v-if="loadingText !== ''" class="loading-message">{{ loadingText }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        loading: {
            type: Boolean
        },
        loadingText: {
            type: String,
            required: false,
            default: ''
        },
        fullPage: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
