<template>
    <div class="legal-content">
        <div class="block">
            This agreement is between Truestock Australia Pty Ltd (Truestock) and you for the licensing of Content from Truestock. By downloading Content from Truestock's website(s) or by confirming your acceptance of this agreement in
            writing, you accept the terms and conditions of this agreement.
            <br><br>
            If you require clarification on any terms and conditions please contact Truestock prior to
            downloading any Content from Truestock's website(s).
            <br><br>
            All capitalised terms used throughout this document have the meaning set out in clause 17
            (Definitions)
        </div>
        <h2>1. Intellectual Property</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    All Content is owned by, or licenced to, either Truestock, its Related Bodies, or its Content Suppliers. All rights to the Content including all Intellectual Property rights are owned by Truestock, its Related Bodies or
                    its Content Suppliers. You agree that, except for the rights expressly provided under this agreement, no other rights are granted or have been transferred to you and you shall not in any way challenge or dispute or make
                    claim to the ownership of, or any right in, the Content.
                </li>
                <li>
                    You shall immediately notify Truestock of any identified or suspected infringement of Content, but may not sue or take any action against any third party in respect of any misuse or infringement of Content without the
                    prior written consent of Truestock.
                </li>
                <li>
                    The rights licensed under this agreement are personal to you. You may not sell, sublicense, distribute, rent, lease, assign, loan, use as security or collateral, or otherwise transfer the Content, or receive revenue from
                    any secondary uses of Content, without the express consent in writing of Truestock.
                </li>
                <li>
                    (d) You do not need to provide an attribution for Commercial Use of Content, but where the Content is used for Editorial Use, you must (unless otherwise agreed by us in writing) attribute the Content to the Content
                    Supplier (by name) and Truestock, as follows:
                    <span class="text-center d-block pt-4 pb-4">&copy; [<i>Content Supplier's name</i>], Truestock</span>
                    (Or such other attribution as we may specify in writing, at any time)
                </li>
                <li>Failure to provide attribution where required is a material breach of this agreement.</li>
            </ol>
        </div>
        <h2>2. Content Licence</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Subject to the terms of this agreement, you are granted a Perpetual, Worldwide, Non-exclusive, Non-transferable and non-sublicensable licence for the Content on the terms and conditions of this agreement.
                </li>
                <li>
                    Content is licenced on an individual item of Content basis, when it is downloaded from Truestock's website(s) or otherwise provided or made available by Truestock. This licence to use, and the right to use, the
                    Content commences from the date of full payment of all amounts owed to Truestock in relation to the Content is received by Truestock, except where Truestock gives express written permission. Each Content download and
                    licence is a separate contract on the terms and conditions of this agreement. You may use the Content in the creation of works and may modify, reproduce, transmit, publish or display the Content, subject to the
                    restrictions in this agreement.
                </li>
                <li>
                    Content displayed on Truestock's website(s) (including thumbnail Content and preview Content) ("Web Display Content") are made available to you for evaluation purposes only. There may be some slight variations
                    between Web Display Content and Content. It is a breach of this agreement and may result in a breach of someone's Intellectual Property rights and result in breach of Intellectual Property laws to use Web Display Content
                    for any final application without purchasing a licence on the terms and conditions of this agreement.
                </li>
                <li>
                    Any use of Content not expressly authorised in this agreement is not permitted and may result in significant loss and damage to us and our Content Suppliers not compensable by monetary damages. Accordingly, you agree
                    that we, our Related Bodies, and our Content Suppliers may be entitled to injunctive or other equitable relief to enforce this agreement and/or prohibit any breach, threatened or actual.
                </li>
                <li>
                    The rights in clause 2(d) is in addition to, and without prejudice to, or limitation on, any other rights we, our Related Bodies, and our Content Suppliers may have under this agreement, at law, or in equity.
                </li>
            </ol>
        </div>
        <h2>3. Licence Extensions</h2>
        <div class="block">
            The following is an additional right granted when you have written acknowledgment from Truestock that the licence extension has been provided and you have paid the relevant additional licence fee:
            <ol class="alpha">
                <li><strong>Unlimited Print:</strong> Unless you have purchased the unlimited print extension, you must not produce physical prints of the Content in excess of 50,000 copies.</li>
                <li>
                    <strong>Commercial Re-print:</strong> Unless you have purchased a commercial re-print extension, you are not permitted to include the Content, in whatever form, in a product intended for resale or distribution (including
                    product packaging). The commercial re-print extension does not permit you to use the Content in Template products.
                </li>
                <li>
                    <strong>Exclusive Use Timeframe:</strong> Truestock will not grant further licences of the Content to any other third party from the commencement date of the exclusive use timeframe until the expiry date of the period.
                    You acknowledge and agree that there will be no restrictions to any licence provided to any other third party prior to the commencement date of the exclusive use timeframe or after the expiry date of the period.
                </li>
                <li>
                    <strong>Television Advertising:</strong> Unless you have purchased a television advertising extension, you are not permitted to include the Content, in whatever form, in a television advertisement. Television
                    advertisement includes broadcasting through terrestrial, satellite or other digital transmission, as well as through on demand streaming platforms providing entertainment services that are the same or similar to that
                    broadcast through other transmission.
                </li>
            </ol>
            Where licence extensions or other specific agreements (including, for completeness, licence extensions or other agreements made in accordance with clause 3, 4, 5 or 6) are made in respect of individual items of Content, these
            are only to apply to the relevant Content and not to other Content or collectively to all Content licensed from Truestock.
        </div>
        <h2>4. Restricted Uses</h2>
        <div class="block">
            You are not permitted to do any of the following:
            <ol class="alpha">
                <li>
                    Use Content in a way that:
                    <ol class="roman">
                        <li>Violates any laws or regulations in any applicable jurisdiction;</li>
                        <li>Infringes any Intellectual Property right;</li>
                        <li>Is considered by Truestock to be (or is considered under any applicable law) pornographic, obscene or defamatory in nature;</li>
                        <li>Allows third parties to download, extract or otherwise access Content as a stand-alone file; or</li>
                        <li>Suggests or implies that a person in the Content is personally endorsing a product, service, idea, opinion or site.</li>
                    </ol>
                </li>
                <li>
                    Incorporate Content into a trademark, registered design, service mark, business name, or logo.
                </li>
                <li>
                    Subject to clause 5, use Content that features an identifiable person or property, regardless of the presence of property release(s) or model release(s) signed by that person(s), to depict or illustrate unflattering
                    situations, or where the use of Content would be unduly controversial to a reasonable person without a disclaimer (for example, where the Content is used to depict or address sexual health). The disclaimer must state
                    that the Content is a stock image being use for illustrative purposes only and that any person in the Content is a model.
                </li>
                <li>
                    Subject to clause 5, use Content that features an identifiable person or property, that is connected or associated with written text that could be interpreted by a reasonable person to be specifically referencing the
                    identifiable person or property in the Content, without a disclaimer, regardless of the presence of property release(s) or model release(s) signed by that person(s). For example, without limiting the restriction, where
                    the written text describes a story or case study about an individual that could be interpreted to be the specific person(s) in the Content. The disclaimer must state that the Content is a stock image being used for
                    illustrative purposes only and that any person in the Content is a model.
                </li>
                <li>
                    Represent that you are the original creator of a work made up of or comprises (in whole or in part) any licensed Content.
                </li>
                <li>Use Content in Template products.</li>
                <li>Use a size of the Content larger than the Content size purchased under this agreement.</li>
            </ol>
        </div>
        <h2>5. Sensitive Use Restriction</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Notwithstanding and in addition to the restrictions in clause 4, you are not permitted to use Content that includes an identifiable person in conjunction with a potentially sensitive subject matter, including but not
                    limited to,:
                    <ol class="roman">
                        <li>actual or implied sexual activity;</li>
                        <li>racial or ethnic origin;</li>
                        <li>political opinions or associations, religious or philosophical beliefs, trade union memberships or associations;</li>
                        <li>substance abuse and recovery;</li>
                        <li>sexually transmitted infections;</li>
                        <li>mental health concerns and disorders (or other health issues);</li>
                        <li>abuse, abortion, promotion of tobacco use, recreational drug use;</li>
                        <li>or any implication that a model engages in an activity that is illegal</li>
                    </ol>
                    ("Sensitive Use") without the prior written consent from Truestock.
                </li>
                <li>The restriction in clause 5(a) does not apply to Content where it is clear the intended purpose of the Content is to convey a Sensitive Use.</li>
            </ol>
        </div>
        <h2>6. First Nations Protocols</h2>
        <div class="block">
            <ol class="alpha">
                <li>You acknowledge that Truestock may make available Content pertaining to indigenous cultures, including depicting individuals, communities, ceremonies and lands.</li>
                <li>
                    Truestock is committed to adhering to cultural protocols in relation to such Content (including prohibitions relating to imagery of deceased persons and sacred sites), and as such you acknowledge that if Truestock is
                    made aware that Content does not align with, or is in infringement of, cultural protocols, you agree that Truestock may:
                    <ol class="roman">
                        <li>in accordance with clause 11, discontinue licencing this Content at its sole discretion; or</li>
                        <li>
                            direct you to either modify your use of the Content or, where appropriate modification is not possible or you refuse to modify;, immediately stop using, and delete or destroy, the Content. This includes
                            (without limitation) the immediate removal of the Content from all printed material, publications, websites and social media. You must comply with such direction.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <h2>7. Releases</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Each Content detail page on Truestock's website(s) lists the type and number of releases available for that Content. Unless otherwise stated on Truestock's website(s), if the Content does not list a release available
                    for the Content, the Content will only be available for Editorial Use.
                </li>
                <li>Model and property releases are available to you upon request, but Truestock may redact information including, but not limited to, private or commercially sensitive information.</li>
            </ol>
        </div>
        <h2>8. Users of Content</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    The rights granted under this agreement are personal to you and cannot be sublicensed or transferred to anyone else, provided however that you may allow a subcontractor or service provider to use the Content where
                    they are assisting in the creation, production or distribution of work for your use. These subcontractors and service providers must agree to, and you warrant that they will, be bound by the terms and conditions of this
                    agreement and must not use the Content for any other purpose.
                </li>
                <li>
                    If you purchase Content on behalf of an employer or client, you represent and warrant that you are authorised to act as their agent and have the legal authority to bind them to the terms and conditions of this
                    agreement. If you do not have authority to legally bind them or they do not agree to the terms and conditions in this agreement, then neither you nor they can use the Content. The rights purchased may only be used by one
                    party and not both (e.g.. you and your client can not both use the licence).
                </li>
                <li>You must provide written notice to Truestock of any change in your details (including but not limited to, changes in your name, address and contact phone number).</li>
            </ol>
        </div>
        <h2>9. Payments and Pricing</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Licence fees for the use of the Content will be quoted to you by Truestock or displayed on Truestock's website(s) and (unless otherwise agreed by us) must be paid by you prior to the downloading or use of the
                    Content. Truestock reserves the right to change the licence fees at any time. Currently pricing will be available by Truestock on its website and will apply to purchases made at that time. If the licence fees for use of
                    the Content quoted to you by Truestock differs from the fee displayed on the Truestock website, the fee quoted by Truestock will prevail
                </li>
                <li>
                    Unless otherwise agreed, you shall pay Truestock the licence fees in full and without set off or deduction by following the payment instructions on Truestock's website(s). You are not permitted to use Content until
                    payment of the licence fees has been made in full, except where Truestock gives express written permission.
                </li>
                <li>
                    If we agree that the licence fees shall be invoiced to you, invoice payments will be due within 10 Working Days of the Content being downloaded by you from Truestock's website(s) or otherwise supplied to you, unless
                    otherwise agreed.
                </li>
                <li>
                    If you fail to pay any money when due to Truestock, you shall indemnify Truestock for all costs and disbursements incurred by Truestock in enforcing its rights under this agreement and in recovering the debt
                    (including but not limited to internal administration fees, legal costs on a solicitor and own client basis, and Truestock's collection agency costs). You shall also pay late-payment interest on any money outstanding at
                    a rate of 2.5% of the overdue amount per month, accruing on a daily basis from the due date until the payment is received in full by Truestock.
                </li>
                <li>Failure to pay licence fees is a material breach of this agreement.</li>
                <li>If you raise any dispute in relation to licence fees payable under this agreement, the undisputed portion of fees shall be paid in a timely fashion in accordance with this clause.</li>
            </ol>
        </div>
        <h2>10. Termination</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Truestock may terminate this agreement at any time if you:
                    <ol class="roman">
                        <li>breach any of the terms or conditions of this agreement; or</li>
                        <li>become bankrupt or insolvent, convene a meeting with creditors or propose or enter into an arrangement with creditors, or make an assignment for the benefit of your creditors; or</li>
                        <li>have a receiver, manager, liquidator (provisional or otherwise) or similar person appointed for you or over any asset of yours.</li>
                    </ol>
                </li>
                <li>
                    In the event of termination, you must immediately stop using, and must delete or destroy, all Content. This includes (without limitation) the immediate removal of the Content from all printed material, publications,
                    websites and social media.
                </li>
                <li>
                    Truestock will endeavour to contact you immediately should it terminate the agreement for any of the reasons above. Truestock will have no liability to you as a result of terminating the agreement.
                </li>
                <li>
                    If you use Content on a social media platform or other third party website and it uses (or asserts a right to use or announces that it plans to use) the Content for its own purposes, the rights granted under this
                    agreement are immediately terminated, and you agree to immediately remove any Content from such platforms or websites.
                </li>
            </ol>
        </div>
        <h2>11. Discontinued Licensing</h2>
        <div class="block">
            <ol class="alpha">
                <li>Truestock may discontinue licensing any Content at any time at its sole discretion.</li>
                <li>
                    If you become aware that Content may be subject to a licensing infringement of a third party's rights where Truestock may be liable, you must cease to use the Content, destroy any Content and ensure that your
                    employer, client and/or subcontractors or service providers do likewise.
                </li>
                <li>
                    In the event of licensing discontinuing due to either 11(a) or 11(b), or clause 6(a) or (b)(ii), you may elect to either have a refund, or license other Content to an equal value for no additional charge, subject to
                    the same terms and conditions of this agreement. This represents your sole remedy, and our sole and complete liability, in the event of the discontinuing of a Content licence.
                </li>
            </ol>
        </div>
        <h2>12. Representations and Warranties</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Truestock represents that, to the best of its knowledge, the use of the Content in accordance with this agreement, excluding any modifications by you, will not infringe or violate Intellectual Property rights or
                    privacy rights of any third party.
                </li>
                <li>
                    Truestock does not make any representation or provide any warranty in respect of the use of names, people, trademarks, logos, designs, works of art, or architecture depicted or contained within Content. While
                    Truestock takes reasonable precaution to control the supply of Content where releases might be required, and to advise you as to the need for, or the existence or non-existence of a release, you are solely responsible
                    for determining whether further releases, consents or licences are required in respect of your intended use of the Content. Where you are in doubt, please contact Truestock before using the Content.
                </li>
                <li>
                    Whilst reasonable efforts have been made to correctly categorise, keyword, caption and title the Content and ensure the accuracy, correctness and reliability of the Content and any metadata provided with the Content,
                    Truestock makes no representations and provides no warranties as to the accuracy, correctness or reliability of such information, or of any metadata provided with the Content and does not accept any liability for loss or
                    damage incurred by you or any third party caused by any such errors.
                </li>
                <li>
                    Except as expressly provided within this agreement, Content is provided "as is" and Truestock makes no further representations and disclaims all warranties relating to the Content, including, but not limited to image
                    quality or fitness for a particular purpose.
                </li>
                <li>
                    To the extent permitted by law, Truestock excludes all other guarantees, warranties, rights or remedies not expressly stated in this clause 12, including any implied warranties under the Australian Consumer Law
                    <strong>(ACL)</strong>.
                </li>
            </ol>
        </div>
        <h2>13. Indemnification</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Truestock agrees to indemnify you for any claim by a third party (including any damages and liability) arising out of any breach by Truestock of clause 12(a) ("IP Claim") , provided that you must:
                    <ol class="roman">
                        <li>promptly notify Truestock in writing of any IP Claim;</li>
                        <li>authorise Truestock to assume sole control over the defence of any IP Claim thereafter, together with the right to settle or compromise such claim;</li>
                        <li>make available to Truestock such information, assistance and authority as may be reasonably requested by Truestock in order to enable Truestock to defend any an IP Claim; and</li>
                        <li>not admit liability for, settle, incur expenses in connection with or otherwise prejudice Truestock's position in respect to the IP Claim.</li>
                    </ol>
                    In the event any IP Claim is asserted, Truestock may without limitation and at its option:
                    <ol class="roman" start="5">
                        <li>obtain such rights and/or licenses from the claimant as may be necessary to enable you to continue using the Content,</li>
                        <li>discontinue the relevant Content licence(s) in accordance with clause 11; and/or</li>
                        <li>modify the Content with respect to which such IP Claim is asserted so as to avoid further claimed infringement.</li>
                    </ol>
                    This indemnity by Truestock will not apply where the Content has not been used in accordance with the terms and conditions of this agreement or where you have breached any aspect of this agreement.
                </li>
                <li>
                    You agree to indemnify and hold Truestock, including its officers, directors, employees, contractors, shareholders, related persons and Content Suppliers harmless for all claims, damages, liabilities and expenses
                    (including legal fees and disbursements on a solicitor and client basis) arising from:
                    <ol class="roman">
                        <li>Your modification of the Content; or</li>
                        <li>The use of the Content including any Sensitive Use of the Content outside of the terms of this agreement (or your failure to comply with a direction made by Truestock under clause 6); or</li>
                        <li>Your failure to obtain any releases for the Content; or</li>
                        <li>Any breach of this agreement by you.</li>
                    </ol>
                </li>
            </ol>
        </div>
        <h2>14. Exclusion and Limitation of Liability</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    Except as specifically stated in this agreement or otherwise required by law, Truestock and its officers, directors, employees, contractors, shareholders, related persons and Content Suppliers will not be liable for
                    damages of any kind, (including without limitation, lost profits, direct, indirect, compensatory, consequential, exemplary, special, incidental, or punitive damages) arising out of your use of or inability to use the
                    Content or arising from this agreement or our website, whether or not we have been advised of the possibility of such damages. This exclusion of liability applies to liability in contract, tort (including negligence) and
                    any other principle or type of legal liability.
                </li>
                <li>
                    Any liability of Truestock arising from or relating to the Content, this agreement or our website, including the liability of our officers, directors, employees, contractors, shareholders, related persons and Content
                    Suppliers collectively, which cannot by law be excluded, shall be limited to a maximum of AUD $500 in aggregate. This limitation of liability shall apply to all remedies available to you whether in contract, tort
                    (including negligence) and any other principle or type of legal liability.
                </li>
            </ol>
        </div>
        <h2>15. Confidentiality</h2>
        <div class="block">
            <ol class="alpha">
                <li>Unless otherwise agreed in writing, the parties shall at all times keep the Confidential Information confidential.</li>
                <li>
                    The parties must not use any Confidential Information supplied by the other party for any purpose other than the performance or exercise of this agreement.
                </li>
                <li>
                    The obligations in this clause above shall apply except:
                    <ol class="roman">
                        <li>As authorised in writing by the party that supplied the information;</li>
                        <li>
                            As reasonably required on a "need to know" basis to officers, employees, and professional advisers of the party for the purposes of implementing this agreement, provided such persons have agreed to keep the
                            Confidential Information confidential;
                        </li>
                        <li>As required by law or to enforce this agreement; or</li>
                        <li>To the extent that any Confidential Information is already, or becomes, public knowledge, other than as a result of a breach of this agreement.</li>
                    </ol>
                </li>
                <li>
                    Notwithstanding any other provision in this agreement, where information or material relates exclusively to one party, nothing in this agreement shall require that party to maintain confidentiality in respect of that
                    information or material.
                </li>
            </ol>
        </div>
        <h2>16. General Provisions</h2>
        <div class="block">
            <ol class="alpha">
                <li>
                    <strong>Governing law and applicable jurisdiction:</strong> This agreement is governed by and construed according to the laws of New South Wales. You irrevocably submit to the non-exclusive jurisdiction of the courts of
                    New South Wales in respect of any dispute or proceeding arising out of this agreement, the Content or our website.
                </li>
                <li>
                    <strong>Third Parties:</strong> This agreement is intended to confer benefits on our officers, directors, employees, contractors, shareholders, related persons and Content Suppliers, and accordingly they may enforce this
                    agreement.
                </li>
                <li>
                    <strong>Assignment:</strong> You may not assign, novate or otherwise deal with your rights and obligations under this agreement without Truestock's prior written consent. Truestock may license, assign and/or novate all
                    or any part of its rights and/or obligations under this agreement by written notice to you.
                </li>
                <li>
                    <strong>Survival:</strong> The covenants, conditions and provisions of this agreement which are capable of having effect after termination shall remain in full force and effect following termination, including, without
                    limitation, clauses 1, 2, 4 – 9 and 11-15.
                </li>
                <li>
                    <strong>Severability:</strong> If any provision of this agreement is or becomes or is held to be or to become illegal, invalid, void or unenforceable in any way, the remaining provisions shall not be affected and shall
                    be severed from this agreement so that the remaining provisions or other part of any provision shall remain in full force and effect and shall be valid and enforceable to the fullest extent permitted by law.
                </li>
                <li>
                    <strong>Entire Agreement:</strong> This agreement (together with the commercial terms of the licence, as selected by you on our website or as otherwise agreed between you and Truestock) contains all the terms and
                    conditions of the agreement and no terms or conditions may be added or deleted unless made in writing and signed by both parties. In the event of any inconsistency between the terms and conditions in this agreement and
                    the commercial licence terms, the terms and conditions of this agreement will govern.
                </li>
                <li>
                    <strong>Electronic storage:</strong> You agree to retain the copyright symbol, Truestock's name, the Content's identification number and any other information or metadata that may be embedded in the electronic file
                    containing the Content, and to maintain appropriate security to protect the Content from unauthorised use by third parties. You may make one copy of the Content for back-up purposes.
                </li>
                <li><strong>Waiver:</strong> No action of either party, other than express written waiver, may be construed as a waiver of any provision of this agreement.</li>
                <li>
                    <strong>Taxes:</strong> You agree to pay and be responsible for any and all sales taxes, user taxes, value-added taxes, withholding taxes and duties imposed by any jurisdiction as a result of the licence granted to you,
                    or of your use of the Content.
                </li>
                <li>
                    <strong>Relationship of the parties:</strong> The relationship of the parties is one of independent contractors. The parties acknowledge that they are not partners, joint-venturers, fiduciaries or agents of the other.
                </li>
                <li>
                    <strong>Notice:</strong> Any communication or notice given under this agreement must be given in writing either personally, or by post or email to the relevant party's communication points as normally used for
                    correspondence (or such other address as may be notified in writing by one party to the other) and will be deemed effective:
                    <ol class="roman">
                        <li>In the case of personal delivery, when delivered;</li>
                        <li>In the case of email, 4 hours after the electronic communication enters the recipient's information system; and</li>
                        <li>In the case of post, on the date that is 10 Working Days after the date of posting.</li>
                    </ol>
                </li>
                <li><strong>Change in address:</strong> You must notify us in writing if there are any changes in your contact details.</li>
                <li>
                    <strong>Interpretation:</strong> In this agreement, unless the context otherwise requires:
                    <ol class="roman">
                        <li>Singular words include the plural and vice versa, and where a word or phrase is defined, its other grammatical forms have a corresponding meaning;</li>
                        <li>
                            Any reference to legislation, statute, regulation, ruling, code, rules or ordinance includes reference to any modification, substitute for, consolidation or re-enactment of it and any regulation, order in council
                            or other instrument from time to time made or issued under such legislation, statute, regulation, ruling, code, rules or ordinance;
                        </li>
                        <li>Headings are for convenience only and do not affect the interpretation of this agreement except for use as cross-references;</li>
                        <li>Any covenant not to do anything shall also constitute an obligation not to suffer, permit, cause or assist any other person to do that thing; and</li>
                        <li>
                            A reference to a "person" includes a body corporate, partnership, joint venture, co-operative, incorporated or unincorporated association, authority, state, government, or government or quasi-government body, as
                            the case may be.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <h2>17. Definitions</h2>
        <div class="block">
            In this agreement, unless the context requires otherwise:
            <ol class="alpha">
                <li>
                    <strong>Commercial Use</strong> means any use to advertise or promote a product, business or service (including advertising, marketing, promotion, packaging, publication covers, advertorials etc), and any other use that
                    is not an Editorial Use.
                </li>
                <li>
                    <strong>Confidential Information</strong> means information of a confidential nature in whatever form (including electronic) whether disclosed before or after this agreement comes into effect including, but not limited
                    to, this agreement, information relating to the other party's business including its financial and commercial affairs, agreements and customer information, Truestock's pricing details, the contents of all negotiations
                    between the parties (if any), and the other party's personal information.
                </li>
                <li>
                    <strong>Content</strong> means the digitised photograph(s), video(s), illustration(s) or other image(s) in electronic or any other medium licensed from Truestock.
                </li>
                <li><strong>Content Supplier(s)</strong> means the individual or legal entity that has provided or licenced the Content to Truestock.</li>
                <li><strong>Editorial Use</strong> means use of Content as part of documenting or reporting on a newsworthy story or event, or for education.</li>
                <li>
                    <strong>Intellectual Property</strong> means all rights in intellectual property (whether registered or unregistered), including all logos, copyright, trademarks, trade mark registrations, service marks, trade names,
                    brands, patents, patent applications, designs, licences, inventions, technical information, technical data, concepts, ideas, moral rights, discoveries, underlying or proprietary data, research, reports, drawings,
                    techniques, processes, practices, systems, specifications, standards, methods, formulae, models, maintenance training or training manuals and other know-how, trade secrets, databases,, lists of customers and suppliers
                    and all other intellectual property rights.
                </li>
                <li>
                    <strong>Non-exclusive</strong> means that you do not have exclusive use of the Content. Truestock may grant a licence in respect of the same Content to as many other customers as it chooses, unless otherwise agreed in
                    writing.
                </li>
                <li><strong>Non-transferable</strong> means that the rights to use the Content cannot be transferred to another person.</li>
                <li><strong>Perpetual</strong> means that there is no expiry or end date on your rights to use the Content.</li>
                <li>
                    <strong>Related Bodies</strong> means as defined in section 50 of the <i>Corporations Act 2001</i>, and for completeness includes (a) any subsidiary or holding company of Truestock Australia Pty Ltd (b) any subsidiary or
                    holding company of a subsidiary or holding company of Truestock Australia Pty Ltd (c) any entity which Truestock Australia Pty Ltd holds a significant interest (either directly or indirectly) in or (d) any entity that
                    holds significant interest (either directly or indirectly) in Truestock Australia Pty Ltd.
                </li>
                <li><strong>Template</strong> means any pre-designed format or structure which may be customised by an individual user by replacing generic elements of the format or structure.</li>
                <li><strong>Truestock, us, our, we</strong> means the legal entity Truestock Australia Pty Ltd (<strong>ABN</strong>: 81 667 409 878).</li>
                <li>
                    <strong>Working Days</strong> means any day of the week, other than:
                    <ol class="roman">
                        <li>Saturdays and Sundays;</li>
                        <li>A public holiday in New South Wales; and</li>
                        <li>A day in the period commencing with the 24th day of December in any year and ending with the 5th day of January in the following year.</li>
                    </ol>
                </li>
                <li><strong>Worldwide</strong> means there is no restriction of use within any geographical jurisdiction.</li>
                <li><strong>You(r) / you(r)</strong> means the person who has agreed to licence Content from Truestock on the terms and conditions of this agreement.</li>
            </ol>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AusContentLicenceAgreementContent'
}
</script>
