<template>
    <b-modal id="contact-modal" scrollable title="Contact Us" hide-footer centered>
        <form class="auth-form" @submit.prevent="sendContact" @keydown="form.onKeydown($event)">
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <label class="required">Name</label>
                    <input v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" type="text" name="name" class="form-control">
                    <has-error :form="form" field="name"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <label class="required">Contact Email</label>
                    <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" type="text" name="email" class="form-control">
                    <has-error :form="form" field="email"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <label class="required">Message</label>
                    <textarea v-model="form.message" rows="4" :class="{ 'is-invalid': form.errors.has('message') }" type="text" name="message" class="form-control"
                              placeholder="What is on your mind?"
                    />
                    <has-error :form="form" field="message"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <v-button variant="primary" class="float-right btn-lg" :loading="form.busy">
                        Submit
                    </v-button>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                    <div class="hint-text">If you would rather send us a plain old email, you can do so at <span class="btn-color">{{ localeVariables['contact-email'] }}</span></div>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
    name: 'ContactModal',
    data () {
        return {
            form: new Form({
                name: '',
                email: '',
                message: '',
                routeName: ''
            })
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            localeVariables: 'data/localeVariables'
        })
    },
    watch: {
        user (newUser, oldUser) {
            this.setUserFields()
        }
    },
    mounted () {
        this.setUserFields()
    },
    methods: {
        setUserFields () {
            if (this.user) {
                this.form.name = `${this.user.first_name} ${this.user.last_name}`
                this.form.email = this.user.email
            }
        },
        async sendContact () {
            this.form.routeName = this.$nuxt.$route.name

            const _this = this
            await this.form.post('/contact/general').then((data) => {
                _this.form.message = ''
                this.$toast.success('Thanks for reaching out! We will get back to you as soon as we can')
                this.$bvModal.hide('contact-modal')
            }).catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>
